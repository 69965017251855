import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import PropTypes from "prop-types";
import { theme } from '../../utils/styling';

const Holder = styled.div`
  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
    background-color: ${props => props.theme.primary};
  }
`;

function ColorScheme({primary, secondary, children}) {
  const scheme = {
    primary: theme.colours[primary],
    secondary: theme.colours[secondary],
  }
  return (
    <ThemeProvider theme={scheme}>
      <Holder>
        {children}
      </Holder>
    </ThemeProvider>
  )
}

ColorScheme.propTypes = {
  primary: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
};

ColorScheme.defaultProps = {
  primary: 'yellow',
  secondary: 'purple',
};

export default ColorScheme;